import ReactDom from 'react-dom'
import './index.css'

const books = [{
  id:1,
  img: "https://images-na.ssl-images-amazon.com/images/I/81F30JDZU9L._AC_UL604_SR604,400_.jpg",
  title: 'Why a Daughter Needs a Dad',
  author: 'Greg E. Lang',
  pages: 120,
}, {
  id:2,
  img: "https://images-na.ssl-images-amazon.com/images/I/81a5KHEkwQL._AC_UL604_SR604,400_.jpg",
  title: "Oh, the Places You'll Go",
  author: 'Dr. Seuss',
  pages: 120,
}];

function BookList(){
  return <section className='booklist'>
 
 {
  books.map((book) => {
    return  <Book key={book.id} {...book}>
    </Book> ;})
 }
    </section>
}

 const Book = ({ img, title, author,pages }) => {
  //const {img, title, author} = props.book;
  return (<article className='book'>
    <img src={img}alt="" />
    <h2>{title}</h2>
    <h4>{author}</h4>
    <h6>Number of pages: {pages}</h6>
  </article>);
}

ReactDom.render(<BookList />, document.getElementById('root'));